<template>
  <div class="m-2">
    <div v-if="cars.length" class="table-responsive">
      <table class="table table-bordered">
        <tr ref="row" class="bg-primary text-white">
          <td>{{ $t("Driver") }}</td>
          <td>{{ $t("Residency Number") }}</td>
          <td>{{ $t("Source of Residence") }}</td>
          <td>{{ $t("Car Number") }}</td>
          <td>{{ $t("Exit Port") }}</td>
          <td>{{ $t("Exit Date") }}</td>
          <td>{{ $t("Type") }}</td>
          <td>{{ $t("Value") }}</td>
          <td>{{ $t("Paid") }}</td>
          <td>{{ $t("Notes") }}</td>
        </tr>
        <tr v-for="(item, index) in cars" :key="index" ref="row">
          <td>
            {{ cars[index].driver }}
          </td>
          <td>
            {{ cars[index].residency_numbe }}
          </td>
          <td>
            {{ cars[index].source_of_residence }}
          </td>
          <td>
            {{ cars[index].car_number }}
          </td>
          <td>
            {{ cars[index].exit_port }}
          </td>
          <td>{{ cars[index].exit_date | formatDate }}></td>

          <td>
            {{ cars[index].type }}
          </td>

          <td>
            {{ cars[index].value }}
          </td>

          <td>
            {{ cars[index].paid }}
          </td>
          <td>
            {{ cars[index].notes }}
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-alert variant="danger" show>
        <div class="alert-body text-center">
          {{ $t("No Data Available.") }}
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BTable, BTr, BTd, BAlert } from "bootstrap-vue";
export default {
  components: {
    BTable,
    BTr,
    BTd,
    BAlert,
  },
  props: {
    cars: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
