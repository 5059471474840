<template>
  <b-row class="m-1">
    <b-col cols="12">
      <table class="table table-condenced table-hover">
        <tr>
          <th>
            {{ $t("Serial") }}
          </th>
          <td>{{ transaction.serial }}</td>
          <th>
            {{ $t("Shipping Date") }}
          </th>
          <td>
            {{ transaction.shipping_date | formatDate }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("Client") }}</th>
          <td>
            <span v-if="transaction.client">
              {{ transaction.client.name }}
            </span>
          </td>
          <th>{{ $t("Sub Client") }}</th>
          <td>
            <span v-if="transaction.child_client">
              {{ transaction.child_client.name }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $t("Transaction Type") }}</th>
          <td>
            <span v-if="transaction.transaction_type">
              {{ transaction.transaction_type.name }}
            </span>
          </td>
          <th>
            {{ $t("Expected Arrival Date") }}
          </th>
          <td>{{ transaction.expected_arrival_date | formatDate }}</td>
        </tr>
        <tr>
          <th>{{ $t("Shipping Port") }}</th>
          <td>
            <span v-if="transaction.shipping_port">
              {{ transaction.shipping_port.name }}
            </span>
          </td>
          <th>{{ $t("Arrival Port") }}</th>
          <td>
            <span v-if="transaction.arrival_port">
              {{ transaction.arrival_port.name }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $t("Transaction Date") }}</th>
          <td>{{ transaction.trans_date | formatDate }}</td>
          <th>{{ $t("Customer Ref Number") }}</th>
          <td>{{ transaction.customer_ref_number }}</td>
        </tr>
        <tr>
          <th>{{ $t("Port Parts") }}</th>
          <td>
            <span v-if="transaction.port_part">
              {{ transaction.port_part.name }}
            </span>
          </td>
          <th>
            {{ $t("Use In Transport Module") }}
          </th>
          <td>
            <feather-icon
              icon="CheckIcon"
              class="text-success"
              v-if="transaction.use_in_transport == '1'"
            />
            <feather-icon icon="XIcon" class="text-danger" v-else />
          </td>
        </tr>
        <tr>
          <th>{{ $t("Transport By") }}</th>
          <td colspan="3">
            {{ transaction.transport_by == "0" ? "الوكيل" : "العميل" }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("Notes") }}</th>
          <td colspan="3">{{ transaction.notes }}</td>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    transaction: {
      type: Object,
      default() {
        return {
          client: "",
        };
      },
    },
  },
};
</script>
