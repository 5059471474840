<template>
  <b-row class="m-1">
    <b-col cols="12">
      <table class="table table-condenced table-hover">
        <tr>
          <th>
            {{ $t("Policy Number") }}
          </th>
          <td>{{ transaction.policy_number }}</td>
          <th>
            {{ $t("Internal Policy Number") }}
          </th>
          <td>
            {{ transaction.internal_policy_number }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("Receive Date") }}</th>
          <td>{{ transaction.policy_receive_date | formatDate }}</td>
          <th>{{ $t("Receive By") }}</th>
          <td>{{ transaction.policy_receive_by }}</td>
        </tr>

        <tr>
          <th>{{ $t("Number of Containers") }}</th>
          <td>{{ transaction.number_of_containers }}</td>
          <th>{{ $t("Number of Packages") }}</th>
          <td>{{ transaction.number_of_packages }}</td>
        </tr>
        <tr>
          <th>{{ $t("Weight (KG)") }}</th>
          <td>{{ transaction.weight }}</td>
          <th>{{ $t("Size (m³)") }}</th>
          <td>{{ transaction.size }}</td>
        </tr>
        <tr>
          <th>{{ $t("Delivary Permission") }}</th>
          <td>{{ transaction.delivary_permission }}</td>
          <th>{{ $t("Delivary Permission Date") }}</th>
          <td>{{ transaction.delivary_permission_date | formatDate }}</td>
        </tr>
        <tr>
          <th>{{ $t("Import Declaration") }}</th>
          <td>{{ transaction.import_declaration }}</td>
          <th>{{ $t("Import Declaration Date") }}</th>
          <td>{{ transaction.import_declaration_date | formatDate }}</td>
        </tr>
        <tr>
          <th>{{ $t("Po Number") }}</th>
          <td colspan="3">{{ transaction.po_number }}</td>
        </tr>
        <tr>
          <th>{{ $t("Description") }}</th>
          <td colspan="3">{{ transaction.policy_desc }}</td>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    transaction: {
      type: Object,
      default() {
        return {
          client: "",
          sub_client: "",
        };
      },
    },
    ports: [],
    portParts: [],
    transTypes: [],
  },
};
</script>
