<template>
  <div class="m-2">
    <div v-if="containers.length" class="table-responsive">
      <table class="table table-bordered">
        <tr ref="row" class="bg-primary text-white">
          <td>{{ $t("Container") }}</td>
          <td>{{ $t("Type") }}</td>
          <td>{{ $t("Temperature") }}</td>
          <td>{{ $t("Phone") }}</td>
          <td>{{ $t("Address") }}</td>
          <td>{{ $t("Admin") }}</td>
          <td>{{ $t("Transport") }}</td>
          <td>{{ $t("From") }}</td>
          <td>{{ $t("To") }}</td>
        </tr>
        <tr v-for="(item, index) in containers" :key="index" ref="row">
          <td>
            {{ containers[index].container }}
          </td>
          <td>
            {{ containers[index].good_type.name }}
          </td>
          <td>
            {{ containers[index].temperature }}
          </td>
          <td>
            {{ containers[index].phone }}
          </td>
          <td>
            {{ containers[index].address }}
          </td>

          <td>
            {{ containers[index].admin }}
          </td>

          <td>
            <feather-icon
              icon="CheckIcon"
              class="text-success"
              v-if="containers[index].use_in_transport == '1'"
            />
            <feather-icon icon="XIcon" class="text-danger" v-else />
          </td>

          <td>
            <span v-if="containers[index].from_city != null">{{
              containers[index].from_city.name
            }}</span>
          </td>

          <td>
            <span v-if="containers[index].to_city != null">
              {{ containers[index].to_city.name }}</span
            >
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-alert variant="danger" show>
        <div class="alert-body text-center">
          {{ $t("No Data Available.") }}
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BTable, BTr, BTd, BAlert } from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BTable,
    BTr,
    BTd,
    BAlert,
  },
  props: {
    containers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {},
  mixins: [heightTransition],
};
</script>

<style scoped>
.table {
  width: 1500px;
}
</style>
