<template>
  <div class="m-2">
    <div v-if="isLoading" class="text-center">
      <b-spinner variant="danger" class="m-2"></b-spinner>
    </div>
    <div v-else-if="achievements.length" class="table-responsive">
      <table class="table table-bordered">
        <tr ref="row" class="bg-primary text-white">
          <td>{{ $t("Code") }}</td>
          <td>{{ $t("Procedure") }}</td>
          <td>{{ $t("Date") }}</td>
          <td>{{ $t("Admin") }}</td>
          <td>{{ $t("Create At") }}</td>
          <td>{{ $t("Notes") }}</td>
        </tr>
        <tr v-for="(item, index) in achievements" :key="index" ref="row">
          <td>
            <b-link class="alert-link" target="_blank">
              {{ item.movement.id }}
            </b-link>
          </td>
          <td>
            <span v-if="item.procedure">
              {{ item.procedure.name }}
            </span>
          </td>
          <td>
            {{ item.movement.movement_date | formatDate }}
          </td>
          <td>
            {{ item.movement.admin.userName }}
          </td>

          <td>
            {{ item.created_at | formatDate }}
          </td>
          <td>
            {{ item.notes }}
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-alert variant="danger" show>
        <div class="alert-body text-center">
          {{ $t("No Data Available.") }}
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BTable, BTr, BTd, BSpinner, BAlert, BLink } from "bootstrap-vue";
export default {
  components: {
    BTable,
    BTr,
    BTd,
    BSpinner,
    BAlert,
    BLink,
  },

  data() {
    return {
      achievements: [],
      isLoading: true,
    };
  },
  created() {
    this.$http
      .get(`crm/client/transactions/${this.$route.params.id}/achievements`)
      .then((response) => {
        this.achievements = response.data.achievements;
        this.isLoading = false;
      });
  },
};
</script>
