<template>
  <div>
    <b-alert variant="warning" :show="transaction.closed == '1'">
      <div class="alert-body">
        <span>
          <feather-icon icon="InfoIcon" class="mr-50" /><strong
            >الرجاء ملاحظة !</strong
          >
          هذه المعاملة مغلقه !</span
        >
      </div>
    </b-alert>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div class="m-1">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>{{ $t("Main Details") }}</span>
                </template>
                <MainDetailsView :transaction="transaction" />
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="BriefcaseIcon" />
                  <span>{{ $t("Policy Data") }}</span>
                </template>

                <PolicyDataView :transaction="transaction" />
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="LayersIcon" />
                  <span>{{ $t("Containers Details") }}</span>
                </template>

                <ContainersView :containers="transaction.containers" />
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="TruckIcon" />
                  <span>{{ $t("Cars Details") }}</span>
                </template>

                <CarsView :cars="transaction.cars" />
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
        <b-card no-body class="mb-1">
          <div class="m-1">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon icon="TrendingUpIcon" />
                  <span>{{ $t("Daily Achievements") }}</span>
                </template>

                <AchievementsView />
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTabs,
  BTab,
  BAlert,
  BTable,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import MainDetailsView from "./components/MainDetailsView.vue";
import PolicyDataView from "./components/PolicyDataView.vue";
import ContainersView from "./components/ContainersView.vue";
import CarsView from "./components/CarsView.vue";
import AchievementsView from "./components/AchievementsView.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
    BTabs,
    BTab,
    MainDetailsView,
    PolicyDataView,
    ContainersView,
    CarsView,
    AchievementsView,
    BAlert,
    BTable,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      transaction: {},
      transTypes: {},
      path: `clearance/transactions/${this.$route.params.id}`,
      isLoading: false,
    };
  },
  created() {
    this.$store.commit("START_LOADING");
    this.$http
      .get(`clearance/transactions/${this.$route.params.id}`)
      .then((response) => {
        this.transaction = response.data.data.transaction;
        this.$store.commit("STOP_LOADING");
      });
  },
  methods: {
    downloadMedia(media) {
      this.$http
        .get(`media/download/${media.id}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          fileDownload(response.data, media.file_name);
        });
    },

    printTransaction() {
      this.Loading = true;
      this.$http
        .get(`clearance/transactions/export/print/${this.transaction.id}`, {
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          var objectURL = URL.createObjectURL(file);
          // window.open(objectURL);

          document.querySelector("#pdf-frame").src = "";
          document.querySelector("#pdf-frame").src = objectURL;
          // objectURL = URL.revokeObjectURL();
          setTimeout(function () {
            URL.revokeObjectURL(file);
          }, 3000);
        })
        .then(function () {
          window.setTimeout(function () {
            document.querySelector("#pdf-frame").contentWindow.print();
          }, 1000);
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    downloadFile() {
      this.$http
        .get(`clearance/transactions/${this.transaction.id}/download`, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, `transaction-${this.transaction.id}.pdf`);
        });
    },
    downloadImagesAsPdf() {
      this.$http
        .get(
          `clearance/transactions/${this.transaction.id}/download-images-as-pdf`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, `transaction-${this.transaction.id}.pdf`);
        });
    },
    reOpenTrans() {
      this.isLoading = true;
      this.$http
        .post(`clearance/transactions/${this.transaction.id}/re-open`)
        .then((response) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("transaction reopened"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.transaction.closed = "0";
        })
        .catch((errors) => {
          this.isLoading = false;
          const rError = errors.response.data.errors;
          const fError = rError
            ? rError[Object.keys(rError)[0]][0]
            : errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: fError,
              icon: "XIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style scoped>
::v-deep #pdf-frame {
  visibility: hidden;
  max-width: 20px;
}
</style>
